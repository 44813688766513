/* Zen.css */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Full-screen container, no scroll bars */
html, body {
  height: 100%;
  overflow: hidden;
}

.zen-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background: #f4e3c1; /* Warm light beige */
}

.zen-content {
  text-align: center;
}

.zen-logo {
  width: 80%;   /* The logo takes up 80% of the width to remain responsive */
  max-width: 600px; /* Ensure the logo doesn't grow too large */
  height: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow for emphasis */
}

/* Responsive Design */
@media (max-width: 768px) {
  .zen-logo {
    width: 90%;
    max-width: 400px;
  }
}
